<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('TypeBases1C')"
    @cancel="onCancel('TypeBases1C')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TypeBase1CEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TypeBases1C',
      pageHeader: 'Редактирование конфигурации',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Конфигурации',
          route: { name: 'TypeBases1C' },
        },
        {
          text: 'Редактирование маршрута',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      platforms: (state) => state.common.platforms,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование тип конфигурации 1С',
            },
            {
              type: 'text',
              key: 'folder',
              label: 'Папка чистой базы',
            },
            {
              type: 'text',
              key: 'fullName',
              label: 'Наименование регистрации',
            },
            {
              type: 'text',
              key: 'releaseNum',
              label: 'Релиз',
            },
            {
              type: 'checkbox',
              key: 'isHideOnReg',
              label: 'Cкрывать при регистрации',
            },
            {
              type: 'select',
              key: 'platformLaunchId',
              label: 'Платформа для запуска',
              list: this.platforms.data,
            },
            {
              type: 'select',
              key: 'platformUpdateId',
              label: 'Платформа для обновления',
              list: this.platforms.data,
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const platforms = this.getCommonList({ name: 'Platforms' });

    Promise.all([platforms]).then(() => {
      this.getItem({ name: this.controllerName, id: this.id })
        .then(() => {
          this.form = this.lodash.cloneDeep(this.item.data);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
